import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Tilt from "react-tilt";

const SectionContainer = styled.section`
  width: 100%;
  margin-top: 130px;
  @media (min-width: 1200px) {
    margin-top: 150px;
  }
`;
const HeroText = styled.div`
  font-family: "Medium";
`;
const TiltWrapper = styled(Tilt)`
  width: 200px;
  height: 200px;
  margin-bottom: 60px;
`
const HeroTextName = styled.div`
  font-size: 177px;
  line-height: 1;

  @media (min-width: 1200px) {
    font-size: 255px;
  }
`;
const HeroTextSlogan = styled.h1`
  font-size: 32px;
  letter-spacing: 1px;
  font-family: "Regular";
  width: 50%;
  @media (min-width: 960px) {
    width: 100%;
  }
  @media (min-width: 1200px) {
    font-size: 50px;
    width: 90%;
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
`;

class Section extends React.Component {
  render() {
    const { onClickHandle } = this.props;
    return (
      <SectionContainer>
        <HeroText>
          <TiltWrapper
            className="Tilt"
            options={{ max: 35 }}
          >
            <HeroTextName>io</HeroTextName>
          </TiltWrapper>
          <HeroTextSlogan>No te pierdas nada.</HeroTextSlogan>
        </HeroText>

        <Button
          name={"Acceder"}
          margin={65}
          onClickHandle={onClickHandle}
          display={window.innerWidth > 1200 ? "none" : "flex"}
        />
      </SectionContainer>
    );
  }
}

export default Section;
