import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
    margin-top: 90px;
`;

const Footer = () => {
  return <FooterContainer>IO by Secuoyas</FooterContainer>;
};

export default Footer;
