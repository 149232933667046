import React from "react";
import styled from "styled-components";
import ProjectItem from "./ProjectItem";

const ListWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #101010;
`;

const List = styled.ul`
  margin: 130px 40px 240px;
  @media (min-width: 1200px) {
    margin: 240px 150px;
  }
`;

class ProjectList extends React.Component {

  render() {
    return (
      <ListWrapper>
        <List>
          <ProjectItem name={"Solera"} />
          <ProjectItem name={"Telxius"} />
          <ProjectItem name={"IE Programas"} />
          <ProjectItem name={"IE Bench"} />
          <ProjectItem name={"Fundación Universia"} />
          <ProjectItem name={"Saddle"} />
          <ProjectItem name={"Calculo"} />
          <ProjectItem name={"Repsol Design System"} />
        </List>
      </ListWrapper>
    );
  }
}

export default ProjectList;
