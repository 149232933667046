import React, { Component } from "react";
import styled from "styled-components";

const NameListItem = styled.li`
  margin-top: 30px;
  @media (min-width: 1200px) {
    margin-top: 45px;
  }
`;
const Name = styled.a`
  font-size: 40px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #9f9f9f;
  }
  @media (min-width: 1200px) {
    font-size: 80px;
  }
`;

class NameComponent extends Component {
  render() {
    const { name } = this.props;
    return (
      <NameListItem>
        <Name>{name}</Name>
      </NameListItem>
    );
  }
}

export default NameComponent;
