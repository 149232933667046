import React from "react";
import styled from "styled-components";

const OptionItem = styled.li`
  font-size: 20px;
  margin-right: 25px;
`;

const OptionItemLink = styled.a`
  color: #9f9f9f;
  transition: 0.3s color ease-in;
  &:hover {
    color: #fff;
  }
`;

class OptionItemComponent extends React.Component {
  render() {
    const { ItemName } = this.props;
    return (
      <OptionItem>
        <OptionItemLink>{ItemName}</OptionItemLink>
      </OptionItem>
    );
  }
}

export default OptionItemComponent;
