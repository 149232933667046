import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TestWrapper = styled.div`
  width: 100%;
  height: 80vh;
  background-color: #101010;
  display: flex;
`;
const Ancor = styled(Link)`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  &:hover {
    color: #707070;
  }
  @media (min-width: 1200px) {
    font-size: 80px;
  }
`;

class Test extends Component {
  render() {
    return (
      <TestWrapper>
        <Ancor to="/distribution">Cliente</Ancor>
        <Ancor to="/projects">Secuoyas</Ancor>
      </TestWrapper>
    );
  }
}

export default Test;
