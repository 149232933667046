import React, { Component, Fragment } from "react";
import styled from "styled-components";
import logo from "../images/logo-sqy.svg";
import UserDataComponent from "./UserDataComponent";
import CompanyLogoComponent from "./CompanyLogoComponent";
import { Switch, Route } from "react-router-dom";

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
  padding: 25px 35px;
`;
const Logo = styled.a`
  display: block;
  width: 32px;
  height: 32px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 1200px) {
    width: 52px;
    height: 52px;
  }
`;

class Header extends Component {
  render() {
    const { bg } = this.props;
    //console.log(bg)
    return (
      <HeaderContainer style={{backgroundColor: `${bg}`}}>
        <Logo href="http://secuoyas.com" />
        <Switch>
          <Route path="/projects" render={() => <UserDataComponent />} />
          <Route
            path="/distribution"
            render={() => (
              <Fragment>
                <CompanyLogoComponent />
                <UserDataComponent />
              </Fragment>
            )}
          />
        </Switch>
      </HeaderContainer>
    );
  }
}

export default Header;
