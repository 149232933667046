import React from "react";
import styled from "styled-components";
import ImageUrl from "../images/user-img.png"

const UserImage = styled.img.attrs({
    src: `${ImageUrl}`
})`
    width: 50px;
    height: 50px;
    margin-right: 25px;
`

const UserIcon = () => {
    return (
      <UserImage/>
    );
  };

  export default UserIcon;