import React from "react";
import styled from "styled-components";

const SettingsItem = styled.li`
    text-align: right;
    margin-bottom: 5px;
    font-size: 21px;
    transition: .3s color ease-in;
    &:hover {
        color: #9f9f9f;
    }
`;

class UserSettingsItem extends React.Component {
    render() {
        const { content } = this.props;
        return (
            <SettingsItem>{content}</SettingsItem>
        );
    }
}

export default UserSettingsItem;