import React, { Component } from "react";
import styled from "styled-components";
import logo from "../images/IE-logo.svg";

const CompanyLogo = styled.div`
  width: 90px;
  height: 33px;
  background-image: url(${logo});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: auto;
  left: 100px;
  @media (min-width: 1200px) {
    width: 145px;
    height: 52px;
    left: 155px;
  }
`;

class CompanyLogoComponent extends Component {
  render() {
    return <CompanyLogo />;
  }
}

export default CompanyLogoComponent;
