import React, { Component, Fragment } from "react";
import styled, { createGlobalStyle } from "styled-components";
// import bg from "./images/bg-desktop.png";
import bg1 from "./images/video-1.mp4";
import bg2 from "./images/video-2.mp4";
import bg3 from "./images/video-3.mp4";
import bg4 from "./images/video-4.mp4";
import bg5 from "./images/video-5.mp4";
import Regular from "./fonts/Gilroy-Regular.woff2";
import Medium from "./fonts/Gilroy-Medium.woff2";
import Semibold from "./fonts/Gilroy-Semibold.woff2";
import Italic from "./fonts/Gilroy-SemiboldItalic.woff2";
import Header from "./components/Header";
import Section from "./components/Section";
import Footer from "./components/Footer";
import AccessScreen from "./components/AccessScreen";
import ProjectList from "./components/ProjectList";
import Distribution from "./components/Distribution";
import Test from "./components/Test";
import { Switch, Route } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
 @font-face {
    font-family: 'Regular';
    src: url('${Regular}') format('woff2');
  }
  @font-face {
    font-family: 'Medium';
    src: url('${Medium}') format('woff2');
  }
  @font-face {
    font-family: 'Semibold';
    src: url('${Semibold}') format('woff2');
  }
  @font-face {
    font-family: 'Italic';
    src: url('${Italic}') format('woff2');
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    ${
      "" /* background-image: url(${bg});
    background-position: left bottom;
    background-size: cover; */
    }
    color: #fff;
    background-color: #101010;
    font-family: 'Regular';
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  ul, ol {
    list-style: none;
  }
`;

const Wrapper = styled.div`
  width: 320px;
  padding: 25px 0 35px;
  margin: 0 auto;
  transition: 1s;

  @media (min-width: 960px) {
    width: 500px;
  }

  @media (min-width: 1200px) {
    width: 400px;
  }

  @media (min-width: 1400px) {
    width: 500px;
  }
`;

const Video = styled.video`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;


// random background
const bgArr = [ bg1, bg2, bg3, bg4, bg5];
const n = Math.floor(Math.random()*bgArr.length);
const bg = bgArr[n];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      x: 0,
      y: 0
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      isOpen: true
    });
  }

  updateDimensions() {
    if (window.innerWidth > 1200) {
      this.setState({ isOpen: true });
    } else {
      this.setState({ isOpen: false });
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {

    return (
      <div className="App">
        <GlobalStyle />
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Fragment>
              <Header />
                <Video
                  className="child"
                  autoPlay
                  loop
                  muted
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <source src={bg} type="video/mp4" />
                </Video>
                <Wrapper
                  style={{
                    margin: window.innerWidth > 1200 ? "0 0 0 200px" : "0 auto"
                  }}
                >
                  <Section
                    onClickHandle={this.handleClick}
                    isOpen={this.state.isOpen}
                  />
                  <Footer />
                  <AccessScreen isOpen={this.state.isOpen} />
                </Wrapper>
              </Fragment>
            )}
          />
          <Route path="/projects" render={() => <Fragment><Header /><ProjectList /></Fragment>} />
          <Route path="/distribution" render={() => <Fragment><Header bg={"#101010"}/><Distribution /></Fragment>} />
          <Route path="/test" render={() =><Fragment> <Header /><Test /></Fragment>} />
        </Switch>
      </div>
    );
  }
}

export default App;
