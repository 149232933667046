import React from "react";
import styled from "styled-components";
import OptionItemComponent from "./OptionItemComponent";
import { CSSTransition } from "react-transition-group";


const ListItem = styled.li`
  margin-top: 30px;
  transition: 0.3s;
  @media (min-width: 1200px) {
    margin-top: 45px;
  }
`;

const ProjectName = styled.span`
    font-size: 40px;
    line-height: 1;
    cursor: pointer;
  @media (min-width: 1200px) {
    font-size: 80px;
  }
`;

const OptionContainer = styled.ul`
  display: flex;
  height: 50px;
  padding-top: 15px;

  &.enter {
    height: 0;
    opacity: 0;
    padding-top: 0;
  }
  &.enter-active {
    height: 75px;
    opacity: 1;
    transition: 0.3s all;
    padding-top: 15px;
  }
  &.enter-done {
    height: 50px;
    transition: 0.4s all;
    padding-top: 15px;
  }
  &.exit {
    height: 50px;
    opacity: 1;
    padding-top: 15px;
  }
  &.exit-active {
    height: 0;
    opacity: 0;
    transition: 0.3s all;
    padding-top: 0;
  }
`;

class ProjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOption: false
    };
  }

  handleToggle = e => {
    e.preventDefault();
    this.setState(prevState => ({
      showOption: !prevState.showOption
    }));
  };

  render() {
    const { name } = this.props;

    return (
      <ListItem>
        <ProjectName onClick={this.handleToggle}>{name}</ProjectName>
        <CSSTransition in={this.state.showOption} timeout={350} unmountOnExit>
          <OptionContainer>
            <OptionItemComponent ItemName={"Dely"} />
            <OptionItemComponent ItemName={"Repo"} />
            <OptionItemComponent ItemName={"Pipeline"} />
          </OptionContainer>
        </CSSTransition>
      </ListItem>
    );
  }
}

export default ProjectItem;
