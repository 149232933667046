import React, { Component } from "react";
import styled from "styled-components";
import UserIcon from "./UserIcon";
import UserSettingsItem from "./UserSettingsItem";
import { CSSTransition } from "react-transition-group";

const UserData = styled.div`
  position: relative;
  cursor: pointer;
  & svg circle {
    transition: 0.3s fill ease-in;
    fill: #ffffff;
  }
  &:hover svg circle {
    fill: #bea382;
  }
`;

const MenuIcon = styled.svg`
  width: 4px;
  height: 20px;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.circle``;

const UserSettings = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 25px;
  height: 100px;
  opacity: 1;
  overflow: hidden;
  transition: .3s all ease-in;

  &.enter {
    height: 0;
    margin-top: 0;
    opacity: 0;
  }
  &.enter-active {
    height: 100px;
    margin-top: 25px;
    opacity: 1;
  }
  &.exit {
    height: 100px;
    margin-top: 25px;
    opacity: 1;
  }
  &.exit-active {
    height: 0;
    margin-top: 0;
    opacity: 0;
  }
`;

class UserDataComponent extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false
    };
  }

  hangleMenuToggle = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }));
  };
  render() {
    const { menuOpen } = this.state;
    return (
      <UserData>
        <UserContainer onClick={this.hangleMenuToggle}>
          <UserIcon/>
          <MenuIcon viewBox="0 0 4 20">
            <Circle
              cx="2"
              cy="2"
              r="2"
              style={{ fill: menuOpen ? "#bea382" : "#ffffff" }}
            />
            <Circle
              cx="2"
              cy="10"
              r="2"
              style={{ fill: menuOpen ? "#bea382" : "#ffffff" }}
            />
            <Circle
              cx="2"
              cy="18"
              r="2"
              style={{ fill: menuOpen ? "#bea382" : "#ffffff" }}
            />
          </MenuIcon>
        </UserContainer>
        <CSSTransition in={this.state.menuOpen} timeout={350} unmountOnExit>
          <UserSettings>
            <UserSettingsItem content={"Mi perfil"} />
            <UserSettingsItem content={"Preferencias"} />
            <UserSettingsItem content={"Salir"}/>
          </UserSettings>
        </CSSTransition>
      </UserData>
    );
  }
}

export default UserDataComponent;
