import React, { Component } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  width: 100%;
  height: 80px;
  position: relative;
  margin-bottom: 10px;
`;

const Placeholder = styled.span`
  display: inline-block;
  position: absolute;
  font-size: 21px;
  right: 100%;
  transform: translateX(100%);
  line-height: 80px;
  transition: 0.5s ease all;
`;

const LabelEmail = styled.label`
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease all;
  border-bottom: 2px solid #404040;
  cursor: text;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 2px;
    visibility: hidden;
    background-color: #fff;
    transition: 0.5s ease all;
  }
  &.active {
    span {
      right: 0;
      font-size: 12px;
      transform: translateX(0);
    }
    &:after {
      width: 100%;
      visibility: visible;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 80px;
  border: none;
  background: none;
  font-size: 21px;
  font-family: "Regular";
  color: #fff;
  outline: none;
  position: relative;
  transition: 0.3s all ease;
  &:focus ~ label {
    span {
      right: 0;
      font-size: 12px;
      transform: translateX(0);
    }
    &:after {
      width: 100%;
      visibility: visible;
    }
  }
`;

class InputComponent extends Component {
  render() {
    const {
      name,
      htmlFor,
      id,
      placeholder,
      type,
      value,
      handleChange,
      handleBlur
    } = this.props;

    return (
      <InputContainer>
        <Input
          value={value}
          name={name}
          id={id}
          type={type}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <LabelEmail htmlFor={htmlFor} className={value ? "active" : ""}>
          <Placeholder>{placeholder}</Placeholder>
        </LabelEmail>
      </InputContainer>
    );
  }
}

export default InputComponent;
