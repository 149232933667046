import React from "react";
import styled from "styled-components";
import Button from "./Button";
import InputComponent from "./InputComponent";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import config from "./auth_config.json";

const ScreenWrapper = styled.div`
  width: 100%;
  background-color: #101010;
  position: absolute;
  top: 0;
  right: -100%;
  bottom: 0;
  transition: 0.6s right ease-in;

  @media (min-width: 1200px) {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const ScreenContainer = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: 120px;

  @media (min-width: 1200px) {
    width: 375px;
  }
`;

const Form = styled.form` 
`;

const Fieldset = styled.fieldset`
  border: none;
`;

const Legend = styled.legend`
  font-size: 30px;
  line-height: 70px;
  margin: 0 0 20px;
  color: #fff;
`;

const PasswordReset = styled.a`
  text-decoration: underline;
  display: block;
  margin-top: 60px;
  font-size: 14px;
  font-family: "Regular";
`;
const StyledLink = styled(Link)`
  display: block;
  margin-top: 40px;
  width: 120px;
  height: 50px;
`;
const ErrorMessage = styled.div`
  font-size: 12px;
  color: #707070;
  height: 100px;
  margin-top: 5px;
  height: 20px;
  opacity: 1;
  transition: 0.3s all ease-in;

  &.enter {
    height: 0;
    margin-top: 0;
    opacity: 0;
  }
  &.enter-active {
    margin-top: 5px;
    height: 20px;
    opacity: 1;
  }
  &.exit {
    margin-top: 5px;
    height: 20px;
    opacity: 1;
  }
  &.exit-active {
    height: 0;
    margin-top: 0;
    opacity: 0;
  }
`;

class AccessScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  render() {
    const { isOpen } = this.props;
    // console.log('AccessScreen component:', isOpen);

    return (
      <ScreenWrapper
        style={{
          right: isOpen ? "0" : "-100%",
          width: window.innerWidth > 1200 ? "630px" : "100%"
        }}
      >
        <ScreenContainer>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Correo electrónico debe ser válido")
                .required("Requerido")
                .test('match',
                        'La combinacion email/password es incorrecta',
                        function(email) {
                        return email === config.email;
                }),
              password: Yup.string()
                .required("Requerido")
		.test('match',
      			'La combinacion email/password es incorrecta',
       			function(password) {
         		return password === config.passw;
       		}),
            })}
	    onSubmit={(values, { setSubmitting }) => {
	          setSubmitting(false);
		  window.location.href = config.href;
	    }}
          >
            {props => {
              const {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
		handleSubmit,
		isSubmitting
              } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <Fieldset>
                    <Legend>Acceso</Legend>
                    <InputComponent
                      value={values.email}
                      name={"email"}
                      id={"email"}
                      placeholder={"email"}
                      type={"email"}
                      htmlFor={"email"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    {/*errors.email && touched.email && <ErrorMessage>{errors.email}</ErrorMessage>*/}
                    <CSSTransition
                      in={errors.email && touched.email}
                      timeout={350}
                      unmountOnExit
                    >
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    </CSSTransition>
                    <InputComponent
                      value={values.password}
                      name={"password"}
                      id={"password"}
                      placeholder={"contraseña"}
                      type={"password"}
                      htmlFor={"password"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <CSSTransition
                      in={errors.password && touched.password}
                      timeout={350}
                      unmountOnExit
                    >
                      <ErrorMessage>{errors.password}</ErrorMessage>
                    </CSSTransition>
                    {/*errors.password && touched.password && <ErrorMessage>{errors.password}</ErrorMessage>*/}
                      <Button type={"submit"} name={"Entrar"} disabled={isSubmitting} />
                  </Fieldset>
                </Form>
              );
            }}
          </Formik>
        </ScreenContainer>
      </ScreenWrapper>
    );
  }
}

export default AccessScreen;
