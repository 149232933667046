import React from "react";
import styled from "styled-components";

const AccessButton = styled.button`
  width: 120px;
  height: 50px;
  background-color: #fff;
  color: #101010;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Semibold";
  font-size: 16px;
  letter-spacing: 1px;
  border: 1px solid #fff;
  border-radius: 2px;
  transition: 0.3s all ease-in-out;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    color: #fff;
  }
`;

const Button = props => {
  return (
    <AccessButton
      type={props.type}
      onClick={props.onClickHandle}
      style={{ 
        marginTop: props.margin + "px",
        display: `${props.display}`
      }}
    >
      {props.name}
    </AccessButton>
  );
};

export default Button;
